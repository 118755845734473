import React from 'react'
import axios from 'axios'
import {useState, useEffect} from 'react'
import { IoMdSave } from "react-icons/io";
import { PencilSquareIcon ,ArchiveBoxXMarkIcon } from '@heroicons/react/24/solid'
import {useNavigate} from 'react-router-dom'
import { MdModeEdit } from "react-icons/md";
import { GrConnect } from "react-icons/gr";
import { FaSearch } from "react-icons/fa";
import { AiFillEye } from "react-icons/ai";
import { AiFillEyeInvisible } from "react-icons/ai";

function StoixeiaEtaireias() {
  {/* Ορίζω μεταβλητές βάσης */}
  
  const [epwnhmia, setEpwnhmia] = useState();
  const [drasthriothta,setDrasthriothta] = useState();
  const [diakritikosTitlos, setDiakritikosTitlos] = useState();
  const [polh,setPolh] = useState();
  const [afm,setAfm] = useState();
  const [dieuthinsi,setDieuthinsi] = useState();
  const [arithmos,setArithmos] = useState();
  const [tk,setTk] = useState();
  const [doy,setDoy] = useState();
  const [gemh,setGemh] = useState();
  const [mhtrow,setMhtrow] = useState();
  const [thlefwno,setThlefwno] = useState();
  const [kinhto,setKinhto] = useState();
  const [email,setEmail] = useState();
  const [logo,setLogo] = useState();
  const [trapezes,setTrapezes] = useState();
  const [parathrhseis, setParathrhseis] = useState();
  const [installCode, setInstallCode] = useState();
  const [taxisUser,setTaxisUser] = useState();
  const [taxisPass, setTaxisPass] = useState();
  const [mydataUser, setMydataUser] = useState();
  const [mydataPass, setMydataPass] = useState();
  const [companyId, setCompanyId] = useState();
  const [disabled,setDisabled] = useState(true);
  const [edit,setEdit] = useState(true);
  const [preview, setPreview] = useState()
  const [imagePath,setImagePath] = useState(true);
  const [change,setChange] = useState(true);
  const [clicked,setClicked] = useState(false);
  const [editTaxis,setEditTaxis] = useState(true);
  const [myDataClicked,setMydataClicked] = useState(false);
  const [editMyData,setEditMyData] = useState(true);
  const [visible,setVisible] = useState(false);
  const navigate = useNavigate();
  const [data,setData] = useState([]);
  {/* Κανω get για να λάβω τις actual μεταβλητές από τη βάση */}
  const getTableData = async () => {

    await axios.get('https://docon.gr/parametroi/stoixeia-etaireias/')
    .then(res=> {
     setData(res.data);
    })
    .catch(err=> console.log(err.response.data));

    
  }
  const onSubmitForm = async (e)=>{
    e.preventDefault();
    const formData = new FormData(); 
    formData.append('company_logo', logo);
    formData.append('epwnhmia',epwnhmia);
    formData.append('drasthriothta',drasthriothta);
    formData.append('diakritikosTitlos',diakritikosTitlos);
    formData.append('polh',polh);
    formData.append('afm',afm);
    formData.append('dieuthinsi',dieuthinsi);
    formData.append('arithmos',arithmos)
    formData.append('tk',tk);
    formData.append('doy',doy);
    formData.append('gemh',gemh);
    formData.append('mhtrwo',mhtrow);
    formData.append('thlefwno',thlefwno);
    formData.append('kinhto',kinhto);
    formData.append('email',email);
    formData.append('trapezes',trapezes);
    formData.append('parathrhseis',parathrhseis);
    formData.append('install_code',installCode);
    formData.append('company_id',companyId);

    await axios.post('https://docon.gr/parametroi/stoixeia-etaireias/' , formData)
    .then(res=>{
       navigate(0);
    }).catch(error=>{
      if(error.response){
        console.log(error.response.data)
      }
      });

  }
  const setmyDisabled = (e)=>{
    e.preventDefault();
    setDisabled(!disabled);
  }

  function handleChange(e) {
    
    setLogo(e.target.files[0]);
    setChange(false);
    const image = e.target.files[0];
    if(image){
      const reader = new FileReader();
      reader.onloadend = () => {
        setPreview(reader.result);
    }
    reader.readAsDataURL(image);
    }
  }
  
{/* Ελέγχω για τυχόν αλλαγές στις μεταβλητές */}
useEffect(()=>{

  getTableData();
 

},[])

const setMyClicked = ()=>{
  setClicked(!clicked);
  console.log("The clicked is " + clicked);
}



useEffect(()=>{
  Object.values(data).map((d,i)=>{
    setEpwnhmia(d.epwnhmia);
    setDrasthriothta(d.drasthriothta);
    setDiakritikosTitlos(d.diakritikosTitlos);
    setPolh(d.polh);
    setAfm(d.afm);
    setDieuthinsi(d.dieuthinsi);
    setArithmos(d.arithmos);
    setTk(d.tk);
    setDoy(d.doy);
    setGemh(d.gemh);
    setMhtrow(d.mhtrow);
    setThlefwno(d.thlefwno);
    setKinhto(d.kinhto);
    setEmail(d.email);
    setLogo(d.logo);
    setTrapezes(d.trapezes);
    setParathrhseis(d.parathrhseis);
    setInstallCode(d.installCode);
   })
},[data])

useEffect(()=>{

  console.log(logo);
  

  
},[logo])

{/* Αρχικό div οθόνης */}
  return (
   

     <div className=" shadow-md overflow-x-hidden flex flex-col justify-items-center p-10 text-center justify-center overflow-y-hidden bg-white mt-5 mx-4 rounded-md">
      <div className="flex flex-col border-[#d1d3e2] border-b shadow-md rounded-md">
      <h1 className="lg:text-2xl text-[#3a3b45] font-semibold flex justify-center">Στοιχεία Εταιρείας</h1>
      <span className="flex text-center font-normal lg:text-1xl md:text-sm sm:text-sm justify-center mt-3 mb-3">Παράμετροι</span>
      </div>
      

     {/* Δημιουργία φόρμας */}

     <form onSubmit = {onSubmitForm} encType = 'multipart/form-data'>
     

   
     
    {/*Loop through στα data από το αρχικό fetch για αρχικοποίηση τιμών φόρμας */}
     
      <div className="flex-col mt-10 md:w-full lg:w-full">
     
        {/* Div επωνυμίας*/}    
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full">
          <label className=" flex justify-items-start justify-left text-left w-[40%]">
          <span className="flex justify-items-start text-red-500">* <span className=" ml-1 flex text-black text-[15px] font-normal ">Επωνυμία:</span></span>
          </label>
          <input type="text" disabled={edit} defaultValue={epwnhmia} onChange={e=>setEpwnhmia(e.target.value)} placeholder="Η επωνυμία της επιχείρησης" className={` ${edit ? "bg-slate-200": "bg-white"} px-3 flex h-9 w-full text-sm mt-2  focus:outline-none focus:border-4 focus:border-[#70b7c5] focus:shadow-md border-2 border-[#d1d3e2] ml-3 outline-none rounded-md `} required />
          </div>

          {/* Div δραστηριότητας*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className="flex justify-items-start justify-left text-left w-[40%]">
          <span className="flex justify-items-start text-red-500">* <span className=" ml-1 flex text-black text-[15px] font-normal ">Δραστηριότητα:</span></span>
          </label>
          <input type="text" disabled={edit} defaultValue={drasthriothta} onChange={e=>setDrasthriothta(e.target.value)} placeholder="Η δραστηριότητα της επιχείρησης" className={` ${edit ? "bg-slate-200": "bg-white"} px-3 flex h-9 w-full text-sm  focus:border-4 border-2 border-[#d1d3e2] focus:outline-none focus:border-[#70b7c5] focus:shadow-md ml-3 outline-none rounded-md `}required />
          </div>

           {/* Div διακριτικός τίτλος*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left md:w-[40%] lg:w-[40%] w-full">
          <span className="flex text-black text-[15px] font-normal py-2">Διακριτικός Τίτλος:</span>
          </label>
          <input type="text" disabled={edit} defaultValue={diakritikosTitlos} onChange={e=>setDiakritikosTitlos(e.target.value)} placeholder="Ο διακριτικός τίτλος" className={` ${edit ? "bg-slate-200": "bg-white"} px-3 flex h-9 w-full text-sm   border-2 focus:border-4 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md `} />
          </div>

           {/* Div Πόλη*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left  w-[40%]">
          <span className="flex justify-items-start text-red-500">* <span className=" ml-1 flex text-black text-[15px] font-normal ">Πόλη:</span></span>
          </label>
          <input type="text" disabled={edit} defaultValue={polh} onChange={e=>setPolh(e.target.value)} placeholder="Η πόλη που δραστηριοποιείται η επιχείρηση" className={` ${edit ? "bg-slate-200": "bg-white"} px-3 flex h-9 w-full text-sm   border-2 focus:border-4 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md `} required/>           
          </div>

           {/* Div ΑΦΜ*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left  w-[40%]">
          <span className="flex justify-items-start text-red-500">*
          <span className="flex text-black text-[15px] font-normal ml-1">ΑΦΜ:</span>
          </span>
          </label>
          <div className="flex-row flex w-full">
          <input type="text" disabled={edit} defaultValue={afm} onChange={e=>setAfm(e.target.value)} placeholder="Το ΑΦΜ της επιχείρησης" className={` ${edit ? "bg-slate-200": "bg-white"} px-3 flex h-9 w-[95%] text-sm mr-2 border-2 focus:outline-none focus:border-4 focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] outline-none rounded-md `} required/>
          <FaSearch className="size-5 my-auto hover:cursor-pointer w-auto flex hover:text-slate-600" onClick={()=>{console.log("icon clicked")}}/>
          </div>
          </div>


           {/* Div Διεύθυνση*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className="justify-items-start justify-left text-left w-[39%]">
          <span className="flex justify-items-start text-red-500">*
          <span className="flex text-black text-[15px] font-normal ml-1">Διεύθυνση:</span>
          </span>
          </label>
            <div className="flex flex-row w-full">
            <input type="text" disabled={edit} defaultValue={dieuthinsi} onChange={e=>setDieuthinsi(e.target.value)} placeholder="Διεύθυνση" className={` ${edit ? "bg-slate-200": "bg-white"} px-3 flex-auto h-9 w-[70%] text-sm border-2 focus:border-4 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md `} required/>
            <input type="number" disabled={edit} defaultValue={arithmos} onChange={e=>setArithmos(e.target.value)} placeholder="Αριθμός" onWheel={ event => event.currentTarget.blur() } className={` ${edit ? "bg-slate-200": "bg-white"} px-1 flex-auto h-9 md:w-[20%] lg:w-[20%] w-[25%] text-sm focus:border-4 border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`} required/>
            <input type="number" disabled={edit} defaultValue={tk} onChange={e=>setTk(e.target.value)} placeholder="ΤΚ." onWheel={ event => event.currentTarget.blur() } className={` ${edit ? "bg-slate-200": "bg-white"} px-1 flex-auto h-9 w-[20%] text-sm focus:border-4 border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`} required/>
            </div>
          </div>


           {/* Div ΔΟΥ*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left  w-[40%]">
          <span className="flex justify-items-start text-red-500">*
          <span className="flex text-black text-[15px] font-normal ml-1">ΔΟΥ:</span>
          </span>
          </label>
          <input type="text" disabled={edit} defaultValue={doy} onChange={e=>setDoy(e.target.value)} placeholder="Η ΔΟΥ που ανήκει η επιχείρηση" className={` ${edit ? "bg-slate-200": "bg-white"} px-3 flex h-9 w-full text-sm border-2  focus:border-4 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md`} required/>
          </div>

           {/* Div ΓΕΜΗ*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left  w-[40%]">
          <span className="flex text-black text-[15px] font-normal ml-1">ΓΕΜΗ:</span>
          </label>
          <input type="text" disabled={edit} defaultValue={gemh} onChange={e=>setGemh(e.target.value)} placeholder="Ο αριθμός ΓΕΜΗ της επιχείρησης" className={` ${edit ? "bg-slate-200": "bg-white"} px-3 flex h-9 w-full text-sm border-2 focus:border-4 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md`}/>
          </div>


           {/* Div Αριθμός Μητρώου*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left  md:w-[40%] lg:w-[40%] w-full">
          <span className="flex text-black text-[15px] font-normal ml-1">Αριθμός μητρώου:</span>
          </label>
          <input type="text" disabled={edit} defaultValue={mhtrow} onChange={e=>setMhtrow(e.target.value)} placeholder="Ο αριθμός μητρώου της επιχείρησης" className={` ${edit ? "bg-slate-200": "bg-white"} px-3 flex h-9 w-full text-sm border-2 focus:border-4 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md`}/>
          </div>

           {/* Div Τηλέφωνο*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left  w-[40%]">
          <span className="flex justify-items-start text-red-500">* <span className=" ml-1 flex text-black text-[15px] font-normal ">Τηλέφωνο:</span></span>
          </label>
          <input type="number" disabled={edit} defaultValue={thlefwno} onChange={e=>setThlefwno(e.target.value)} placeholder="Ο αριθμός τηλεφώνου της επιχείρησης" className={` ${edit ? "bg-slate-200": "bg-white"} px-3 flex h-9 w-full text-sm  focus:border-4 border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`} required/>
          </div>

           {/* Div Κινητό*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left  w-[40%]">
          <span className="flex justify-items-start text-red-500">* <span className=" ml-1 flex text-black text-[15px] font-normal ">Κινητό:</span></span>
          </label>
          <input type="number" disabled={edit} defaultValue={kinhto} onChange={e=>setKinhto(e.target.value)} placeholder="Το κινητό τηλέφωνο της επιχείρησης" className={` ${edit ? "bg-slate-200": "bg-white"} px-3 flex h-9 w-full text-sm focus:border-4  border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none`} required/>
          </div>

           {/* Div Email*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left w-[40%]">
          <span className="flex justify-items-start text-red-500">*
          <span className="flex text-black text-[15px] font-normal ml-1">Email:</span>
          </span>
          </label>
          <input type="text" disabled={edit} defaultValue={email} onChange={e=>setEmail(e.target.value)} placeholder="Το email της επιχείρησης" className={` ${edit ? "bg-slate-200": "bg-white"} px-3 flex h-9 w-full text-sm border-2 focus:border-4 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md`} />
          </div>

           {/* Div Logo*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left w-[40%]">
          <span className="flex text-black text-[15px] font-normal py-2">Logo:</span>
          </label>
          <div className="flex flex-col w-full lg:flex-row md:flex-row">
          <input type="file"  disabled={edit} onChange={handleChange} multiple = {false} defaultValue={logo} accept ="image/*" className=" px-2 flex h-9 w-[40%] text-sm bg-white focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] focus:border-4 outline-none rounded-md " />
          <p className="flex text-1xl font-semibold mr-2">Επιλεγμένο logo:</p>
          {logo && change ? <img src={require('C:/Users/dai18/OneDrive/Έγγραφα/GitHub/topsoft_cloud/client/src/media/' + logo)} className="flex w-20 h-20" />  : <img src={preview} className="flex w-20 h-20" /> }          
          </div>
          </div>


           {/* Div Τραπεζικοί λογαριασμοί*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left md:w-[40%] lg:w-[40%] w-full">
          <span className="flex text-black text-[15px] font-normal py-2">Τραπεζικοί λογαριασμοί:</span>
          </label>
          <input type="text" disabled={edit} defaultValue={trapezes} onChange={e=>setTrapezes(e.target.value)} placeholder="πχ Eurobank GR5896625896, Πειραιώς GR45224454447" className={` ${edit ? "bg-slate-200": "bg-white"} px-3 flex h-9 w-full text-sm focus:border-4 border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md`} />
          </div>


           {/* Div Σταθερές παρατηρήσεις στα Παραστατικά*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className=" flex justify-items-start justify-left text-left md:w-[40%] lg:w-[40%] w-full">
          <span className="flex text-black text-[15px] font-normal py-2">Σταθερές παρατηρήσεις στα Παραστατικά:</span>
          </label>
          <input type="text" disabled={edit} defaultValue={parathrhseis} onChange={e=>setParathrhseis(e.target.value)} placeholder="πχ Επιστροφές δεκτές μόνο με αποστολή σχετικού δελτίου-απόδειξης" className={` ${edit ? "bg-slate-200": "bg-white"} px-3 flex h-9 w-full text-sm focus:border-4  border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md`} />
          </div>


           {/* Div Κωδικός Εγκατάστασης*/} 
          <div className="flex flex-col md:flex md:flex-row lg:flex lg:flex-row md:w-full lg:w-full mt-8">
          <label className="flex flex-row justify-items-start justify-left text-left">
          <span className="flex justify-items-start text-red-500">*
          <span className="flex text-black text-[15px] font-normal ml-1">Κωδικός Εγκατάστασης:</span>
          </span>
          <input type="number" disabled={disabled} defaultValue={installCode || '0'} onChange={e=>setInstallCode(e.target.value)} onWheel={ event => event.currentTarget.blur() } className={` ${disabled ? "bg-slate-200": "bg-white [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none"} "my-auto px-3 flex h-9 w-[15%] text-sm focus:border-4  border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] ml-3 outline-none rounded-md [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none `} required/>
          <button className="flex ml-4 my-auto text-[#1a97af] hover:text-[#70b7c57a]" onClick={e=>setmyDisabled(e)}>
            <PencilSquareIcon className="size-8 "/>
          </button>
          </label>
          </div>

           {/* Div Καταχώρησης*/} 
          <div className="flex flex-row justify-between mt-10 w-full">
          <div className="flex">
          <p className="flex-1 flex-row font-thin text-sm mr-2">Τα πεδία με ( <span className="text-red-400">*</span>  ) είναι υποχρεωτικά</p>
          </div>
          <div className="flex">
          <button type="button" className=" w-30 h-10 mr-3 flex flex-row float-right justify-end bg-[#818a83] hover:bg-[#818a8381] text-white font-semibold py-2 px-3 border rounded-md" onClick={()=>setEdit(false)}>
          <MdModeEdit className="size-4 my-auto"/>
          <span className="text-sm">Επεξεργασία</span>
          </button>
          <button className=" w-30 h-10 flex flex-row float-right justify-end bg-[#186c2a] hover:bg-[#186c2a77] text-white font-semibold py-2 px-3 border rounded-md">
          <IoMdSave className="size-4 my-auto"/>
          <span className="text-sm">Καταχώρηση</span>
          </button>
          </div>
          </div>
          </div>
          </form>

          <div className="w-full bg-slate-500 mt-5 hover:cursor-pointer  rounded-t-md" onClick={()=>setMyClicked()}>
            <h3 className="font-semibold text-1xl flex px-3 text-white my-auto py-3">Λήψη στοιχείων πελατών από Taxis</h3>
            </div>
            
            <div className={` ${clicked ? "w-full bg-slate-300 px-2 max-h-600 pl-5 pt-7 rounded-b-md pb-4" : "max-h-0 invisible"} `}>
             
             <label className="flex flex-row lg:w-auto md:w-auto w-full">
              <span className="flex text-sm justify-items-start justify-left text-left">Κωδικός Εισόδου taxis AFM :</span>
              <input type="text" disabled={editTaxis} defaultValue={taxisUser} onChange={e=>setTaxisUser(e.target.value)} placeholder="Κωδικός εισόδου" className={` ${editTaxis ? "bg-slate-200": "bg-white"} ml-16 px-3 flex h-10 w-[70%] text-sm focus:border-4  border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] outline-none rounded-md`} />
             </label>
             <div className="flex flex-col w-full lg:flex-row md:flex-row">
             <div className="flex lg:w-[70%] md:w-[70%] w-full">
             <label className="flex flex-row w-full mt-10">
              <span className="flex text-sm justify-items-start justify-left text-left">Συνθηματικό Χρήστη taxis AFM :  </span>
              <div className={` ${editTaxis ? "bg-slate-200": "bg-white"} flex flex-1 justify-between items-center shadow-sm focus-within:border-4 mx-8 focus-within:outline-none border-1 focus-within:border-[#70b7c5] focus-within:shadow-md border-[#d1d3e2] outline-none rounded-md h-10 `}>
              <input type={visible? "text" : "password"} disabled={editTaxis} defaultValue={taxisPass} onChange={e=>setTaxisPass(e.target.value)} placeholder="Συνθηματικό εισόδου" className="w-full pl-5 px-3 border-none focus:border-none focus:outline-none text-sm h-8" />
              <div className="p-2" onClick={()=>setVisible(!visible)}>
              {visible ? <AiFillEye /> : <AiFillEyeInvisible />}
              </div>
              </div>
             </label>
             </div>
             <div className="flex flex-row mt-10 lg:w-[30%] md:w-[30%] w-full">
             <a href="https://www.aade.gr/anazitisi-basikon-stoiheion-mitrooy-epiheiriseon" rel="noreferrer" target="_blank" className="flex bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2.5 px-4 rounded-md text-sm mr-5 my-auto">Link για Taxis</a>
             <button type="button" className=" w-30 h-10 mr-3 flex flex-row float-right justify-end bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-3 border rounded-md my-auto" onClick={()=>{}}>
              <GrConnect className="size-4 my-auto"/>
              <span className="text-sm">Δοκιμή σύνδεσης</span>
              </button>
              </div>
              </div>
             <div className="flex justify-end mt-20 pr-2">
             <button type="button" className=" w-30 h-10 mr-3 flex flex-row float-right justify-end bg-[#818a83] hover:bg-[#818a8381] text-white font-semibold py-2 px-3 border rounded-md" onClick={()=>setEditTaxis(false)}>
              <MdModeEdit className="size-5 my-auto"/>
              <span className="text-sm">Επεξεργασία</span>
              </button>
             <button className=" w-30 h-10 flex float-right justify-end bg-[#186c2a] hover:bg-[#186c2a77] text-white font-semibold py-2 px-3 border rounded-md">
              <IoMdSave className="size-4 my-auto"/>
              <span className="text-sm">Αποθήκευση</span>
              </button>
              </div>
            </div>

            <div className="w-full bg-slate-500 mt-5 hover:cursor-pointer  rounded-t-md" onClick={()=>setMydataClicked(!myDataClicked)}>
            <h3 className="font-semibold text-1xl flex px-3 text-white my-auto py-3">Κωδικοί για την επικοινωνία με το MyData</h3>
            </div>
            
            <div className={` ${myDataClicked ? "w-full bg-slate-300 px-2 max-h-600 pl-5 pt-7 rounded-b-md pb-4" : "max-h-0 invisible"} `}>
             
             <label className="flex flex-row lg:w-auto md:w-auto w-full">
              <span className="flex text-sm justify-items-start justify-left text-left">MyData Username :</span>
              <input type="text" disabled={editMyData} defaultValue={mydataUser} onChange={e=>setMydataUser(e.target.value)} placeholder="Κωδικός εισόδου" className={` ${editMyData ? "bg-slate-200": "bg-white"} ml-16 px-3 flex h-10 w-[70%] text-sm focus:border-4  border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] outline-none rounded-md`} />
             </label>
             <div className="flex flex-col w-full lg:flex-row md:flex-row">
             <div className="flex lg:w-[65%] md:w-[65%] w-full">
             <label className="flex flex-row w-full mt-10">
              <span className="flex text-sm justify-items-start justify-left text-left">MyData RestApiKey :  </span>
              <input type="text" disabled={editMyData} defaultValue={mydataPass} onChange={e=>setMydataPass(e.target.value)} placeholder="RestApiKey" className={` ${editMyData ? "bg-slate-200": "bg-white"} ml-10 px-3 flex flex-1 h-10 w-[50%] text-sm focus:border-4  border-2 focus:outline-none focus:border-[#70b7c5] focus:shadow-md border-[#d1d3e2] outline-none rounded-md`} />
             </label>
             </div>
             <div className="flex flex-row mt-10 ml-5 lg:w-[35%] md:w-[35%] w-full">
             <a href="https://login.gsis.gr/mylogin/login.jsp?bmctx=1DB55AB50C08F2B418903DE4EB7466AD47038BC455E39B9EA82B1EB28CE52BC6&contextType=external&username=string&password=secure_string&challenge_url=https%3A%2F%2Flogin.gsis.gr%2Fmylogin%2Flogin.jsp&ssoCookie=disablehttponly&request_id=203311117521980261&authn_try_count=0&locale=el&resource_url=https%253A%252F%252Fwww1.aade.gr%252Fsaadeapps2%252Fbookkeeper-web" rel="noreferrer" target="_blank" className="flex bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2.5 px-4 rounded-md text-sm mr-5 my-auto">Link MyData</a>
             <button type="button" className=" w-30 h-10 mr-3 flex flex-row float-right justify-end bg-blue-500 hover:bg-blue-700 text-white font-semibold py-2 px-3 border rounded-md my-auto" onClick={()=>{}}>
              <GrConnect className="size-4 my-auto"/>
              <span className="text-sm">Δοκιμή σύνδεσης</span>
              </button>
              </div>
              </div>
             <div className="flex justify-end mt-20 pr-2">
             <button type="button" className=" w-30 h-10 mr-3 flex flex-row float-right justify-end bg-[#818a83] hover:bg-[#818a8381] text-white font-semibold py-2 px-3 border rounded-md" onClick={()=>setEditMyData(false)}>
              <MdModeEdit className="size-5 my-auto"/>
              <span className="text-sm">Επεξεργασία</span>
              </button>
             <button className=" w-30 h-10 flex float-right justify-end bg-[#186c2a] hover:bg-[#186c2a77] text-white font-semibold py-2 px-3 border rounded-md">
              <IoMdSave className="size-4 my-auto"/>
              <span className="text-sm">Αποθήκευση</span>
              </button>
              </div>
            </div>
            
            
            
</div>


     
  )
}

export default StoixeiaEtaireias